@font-face {
  font-family: 'Bryant MOO Pro';
  src: url('https://eu-assets.contentstack.com/v3/assets/blt128c52d5bd39c039/blt56af8622c994760c/BryantMooWebProRegular.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bryant MOO Pro';
  src: url('https://eu-assets.contentstack.com/v3/assets/blt128c52d5bd39c039/blt176d7c3fac08ba22/BryantMooWebProMedium.woff2')
    format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Bryant MOO Pro';
  src: url('https://eu-assets.contentstack.com/v3/assets/blt128c52d5bd39c039/blt176d7c3fac08ba22/BryantMooWebProMedium.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Rounded Pro';
  src: url('https://eu-assets.contentstack.com/v3/assets/blt128c52d5bd39c039/blt44121bd45f6e7e98/AvenirNextRoundedPro-Reg.woff2')
    format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Rounded Pro';
  src: url('https://eu-assets.contentstack.com/v3/assets/blt128c52d5bd39c039/bltced7889aca3f4e46/AvenirNextRoundedPro-Med.woff2')
    format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Rounded Pro';
  src: url('https://eu-assets.contentstack.com/v3/assets/blt128c52d5bd39c039/bltced7889aca3f4e46/AvenirNextRoundedPro-Med.woff2')
    format('woff2');
  font-weight: 500;
  font-style: normal;
}

body {
  color: var(--mantine-color-dark-8);
  background-color: var(--mantine-palette-surface-cloud);
  --mantine-transition-timing-function: ease-in-out;
}
