.container {
  height: 100svh;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-lg);
  width: 100%;
  max-width: 1224px;
  margin: var(--mantine-spacing-sm) auto;
  background-color: var(--mantine-color-white);
}
