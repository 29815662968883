.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-lg);
}

.logo__wrapper {
  width: 100px;
  min-width: 100px;
}

.nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  margin-left: var(--mantine-spacing-lg);
  &__item {
    margin-right: 20px;
  }

  &__link {
    color: var(--mantine-color-dark-7);
    text-decoration: none;
  }
}
