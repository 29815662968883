.wrapper {
  padding: var(--mantine-spacing-md) 0;
  background-color: var(--mantine-palette-neutral-200);
  padding: var(--mantine-spacing-sm) var(--mantine-spacing-lg);
}

.innerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--mantine-spacing-sm);
  margin: 0 auto;
}

.linkList {
  display: flex;
  flex-wrap: wrap;
  gap: var(--mantine-spacing-sm);
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  list-style: none;
  font-size: var(--mantine-font-size-xs);

  li {
    margin: 0;
    padding: 0;
  }
}

.legalLinks {
  @media (min-width: $mantine-breakpoint-sm) {
    justify-content: start;
  }
}

.legalLink {
  font-family: var(--mantine-font-family-label);
  font-weight: 500;
  color: var(--mantine-palette-brand-dark);
}

.divider {
  align-self: stretch;
}

.localeAndLinksWrapper {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  gap: var(--mantine-spacing-sm);

  @media (min-width: $mantine-breakpoint-sm) {
    align-items: start;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.bottomWrapper {
  align-self: stretch;
  display: grid;
  gap: var(--mantine-spacing-md) var(--mantine-spacing-xxl);
  align-items: center;
  grid-template-areas:
    'copyright copyright'
    'logo      socials';

  @media (min-width: $mantine-breakpoint-sm) {
    grid-template-areas: 'logo copyright socials';
  }
}

.logoWrapper {
  grid-area: logo;
  width: 77px;
  height: 24px;
}

.copyrightText {
  grid-area: copyright;
  font-family: var(--mantine-font-family-label);
  font-weight: 500;
  text-align: center;
}

.socialLinks {
  grid-area: socials;
  justify-content: end;
}
